<template>
    <div>
        <div class="page-title">收银台</div>

        <div>
            <div class="mb-4" v-if="showWarning">
                <a-alert message="你暂未开通服务商分账功能，请授权开通后使用" type="error"/>
            </div>
            <a-table
                    class="stock-table"
                    :columns="table.columns"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <div slot="idx" slot-scope="val,item">
                    {{ (query.page - 1) * 20 + table.data.indexOf(item) + 1 }}
                </div>

                <div slot="time" slot-scope="time">
                    {{ formatTime(time) }}
                </div>

                <div slot="transaction_id" slot-scope="transactionId">

                    <a-tooltip>
                        <template slot="title">
                            <div style="width: 150px">{{ transactionId }}</div>
                        </template>
                        {{ transactionId.substring(16, transactionId.length) }}
                    </a-tooltip>

                </div>

                <div slot="payer" slot-scope="val,item">
                    <router-link v-if="item.member" :to="`/ast/members/${item.member.id}`">
                        <div class="member-card">
                            <div><img :src="item.member.avatar" alt=""></div>
                            <span class="member-name">{{ item.member.mark_name || item.member.name }}</span>
                        </div>
                        <div class="member-id">{{ item.member.member_id }}</div>
                    </router-link>
                </div>

                <div slot="parent" slot-scope="val,item">

                    <template v-if="item.parent">

                        <router-link v-if="item.parent" :to="`/ast/members/${item.parent.id}`">
                            <div class="member-card">
                                <img :src="item.parent.avatar" alt="">
                                <span class="member-name">{{ item.parent.mark_name || item.parent.name }}</span>
                            </div>
                            <div class="member-id">{{ item.parent.member_id }}</div>
                        </router-link>

                    </template>


                </div>

                <div slot="money" slot-scope="money">
                    ￥{{ money }}
                </div>

                <div slot="remark" slot-scope="remark">
                    <template v-if="remark.length > 10">
                        <a-tooltip>
                            <template slot="title">
                                <div style="width: 150px">{{ remark }}</div>
                            </template>
                            <div class="remark">{{ remark.substring(0, 10) }}...</div>
                        </a-tooltip>
                    </template>

                    <template v-else>
                        <div class="remark">{{ remark }}</div>
                    </template>
                </div>

                <div slot="op" slot-scope="val,item">
                    <router-link :to="`/ast/shop/cashier/sharing/${item.id}`">
                        <template v-if="item.sharing === 1 || item.sharing === 3 ">
                            <button class="btn mb-4">分账</button>
                        </template>
                        <template v-else>
                            完结
                            <div> {{ formatTime(item.updated_at) }}</div>
                        </template>
                    </router-link>
                    
                    <div v-if="item.sharing === 3">
                        <a-popover title="原因">
                            <template slot="content">
                                {{ item.sharing_error }}
                            </template>
                            <span><small>分账失败,<a>查看原因</a></small></span>
                        </a-popover>
                    </div>
                    
                    <div v-if="item.sharing === 1">
                        
                        <small>根据交易监管政策，<br />该笔收款T+1天后方可进行分账完结操作</small>
                        <br>
                        <share-count-down-one class="mt-4" type="two" :time="item.auto_sharing_time"></share-count-down-one>
                        
                    </div>
                    
                    <div v-if="item.sharing === 3">
                        <share-count-down-one class="mt-4" type="two" :time="item.auto_sharing_time"></share-count-down-one>
                    </div>
                    
                </div>
            </a-table>
        </div>
    </div>
</template>

<script>
import api from "../../../repo/api";
import moment from 'moment';
import ShareCountDownOne from "@/views/AST/Cashier/Com/ShareCountDownOne.vue";

export default {
    name: "GoodsSync",
    components: {ShareCountDownOne},
    data() {

        return {
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        align: 'center',
                    },
                    {
                        title: '时间',
                        dataIndex: 'created_at',
                        align: 'center',
                        scopedSlots: {customRender: 'time'},
                    },
                    {
                        title: '订单号',
                        dataIndex: 'transaction_id',
                        align: 'center',
                        scopedSlots: {customRender: 'transaction_id'},
                    },
                    {
                        title: '购买用户',
                        dataIndex: 'payer',
                        align: 'left',
                        width: '150px',
                        scopedSlots: {customRender: 'payer'},
                    },
                    {
                        title: '上级推荐',
                        dataIndex: 'shop_price',
                        align: 'left',
                        width: '150px',
                        scopedSlots: {customRender: 'parent'},
                    },
                    {
                        title: '付款金额',
                        dataIndex: 'pay_amount',
                        align: 'center',
                        scopedSlots: {customRender: 'money'},
                    },
                    {
                        title: '冻结天数',
                        dataIndex: 'unlock_day',
                        align: 'center',
                    },
                    {
                        title: '付款备注',
                        dataIndex: 'subject',
                        align: 'left',
                        scopedSlots: {customRender: 'remark'},
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        align: 'center',
                        scopedSlots: {customRender: 'op'},
                    }
                ],
                loading: false,
                data: [],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
            list: [],
            fileName: '',
            query: {
                page: 1
            },
            log: {},
            showWarning: false
        }
    },
    mounted() {
        this.getFirstList()
        this.checkPayStatus()
    },
    methods: {
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        checkPayStatus() {
            api.post('/ast-cashier/check-share-pay-status', (res) => {
                this.showWarning = (res.data.status <= 0)
            })
        },
        getList() {

            this.table.loading = true

            api.get('/ast-cashier/orders', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },

        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>

<style lang="less" scoped>
.mt16 {
    margin-top: 16px;
}

.member-card {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    
    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        overflow: hidden;
        height: 20px;
        flex: 1;
    }
}

.cj {
    color: #FBB03B;
}

.yc-only .member-name {
    color: #FBB03B;
}

.remark {
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
}

.member-id {
    height: 20px;
    text-align: center;
    background-color: #ddd;
    font-size: 12px;
    margin-top: 5px;
    color: white;
    position: relative;
    padding: 2px 2px 2px 10px;

    &:before {
        position: absolute;
        left: 5px;
        content: ' ID ';
    }
}

</style>